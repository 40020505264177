import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { motion, useAnimation } from "framer-motion"

import { GiPaintBucket } from "react-icons/gi"
import { FaGlobe, FaLaptopCode, FaGithubAlt, FaDatabase } from "react-icons/fa"

const PortfolioShowcase = ({ data }) => {
  const [title, setTitle] = useState("Please select a project!")
  const [description, setDescription] = useState()
  const [github, setGithub] = useState()
  const [siteLink, setSiteLink] = useState()
  const [stack, setStack] = useState()
  const [cms, setCms] = useState()
  const [design, setDesign] = useState()
  const [selected, setSelected] = useState(false)

  const controls = useAnimation()

  controls.start({
    scale: 1,
  })


  const updateProject = (item) => {
    setSelected(true)
    setTitle(item.title)
    setDescription(item.description)
    setSiteLink(item.link)
    setGithub(item.git)
    setStack(item.stack)
    setDesign(item.design)
    setCms(item.cms)
    controls.start({
      scale: 1,
    })
  }

  const ItemSelector = ({ item }) => {
    return (
      <Col xs={6} md={2} className="project-selector" onClick={() => updateProject(item)}>
        <motion.span className="premium-button" whileTap={{ scale: 0.7 }}>{item.title}</motion.span>
      </Col>
    )
  }

  return (
    <Container id="portfolio-showcase">
      <Row>
        {data.map((item, i) => <ItemSelector item={item} key={i}/>)}
      </Row>
      <Row>
        <Col className="project">
          <motion.span className="title" initial={{ scale: 0.5 }} animate={controls}>
            {title}
            {selected &&
            <motion.a href={siteLink} target="_blank" rel="noopener noreferrer" animate={{ scale: 1.2 }} transition={{
              flip: Infinity,
              ease: "linear",
              duration: 1,
            }}>
              <span className="svg-container"><FaGlobe/></span></motion.a>
            }
          </motion.span>
          {selected &&
          <>
            <p className="description">{description}</p>
            <div className="stack-details">
              {stack ? <p><FaLaptopCode/> Stack Used :</p> : null}
              {stack ? stack.map((item, i) => <span key={i} className="stack-item">{item}</span>) : null}
            </div>
            <div className="design-details">
              {design ? <p><GiPaintBucket/> Design :</p> : null}
              {design ? design.map((item, i) => <span key={i} className="design-item">{item}</span>) : null}
            </div>
            <div className="cms-details">
              {cms ? <p><FaDatabase/> Additionally, this site uses data from {cms}</p> : null}
            </div>
            <ul className="links">
              <li>To view this project please visit <a href={siteLink} target="_blank"
                                                       rel="noopener noreferrer">Link</a>
              </li>
              <li>{github ?
                <span>To view this project's code, go here
                <a href={github} target="_blank" rel="noopener noreferrer"> <FaGithubAlt/></a></span> :
                <span>This project is private, for github code please send me an email at
                <a href="mailto:jaykch@outlook.com"> jaykch@outlook.com</a></span>
              }</li>
            </ul>
          </>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default PortfolioShowcase
