// --------------------Projects------------------------

const excyted = {
  title: "Excyted.io",
  description: "Portfolio site for a design agency",
  link: "https://excyted.io/",
  git: "https://github.com/jaykch/thecreatorluke",
  stack: ["Gatsby.Js", "React.Js", "React Helmet", "GraphQL"],
  cms: "Contentful (Headless CMS)",
  design: ["Bootstrap", "Icon creation", "Web Design", "Animations"],
}
const enenza = {
  title: "Enenza",
  description: "Full Stack Booking app for a taxi service in london",
  link: "https://enenza.jaykch.com/",
  stack: ["Gatsby.Js", "React.Js", "React Helmet", "Node.Js", "Passport.Js"],
  design: ["Bootstrap", "Icon creation", "Web Design", "Animations"],
}
const jayDocs = {
  title: "JayDocs",
  description: "Gatsby style documentation site that automatically generates pages from contentful data",
  link: "http://docs.jaykch.com/",
  stack: ["Gatsby.Js", "React.Js", "React Helmet", "GraphQL", "Algolia"],
  cms: "Contentful (Headless CMS)",
  design: ["Bootstrap", "Icon creation", "Web Design", "Animations"],
}
const vipRides = {
  title: "Vip Rides",
  description: "Small business site for a taxi service in Bendigo, Australia",
  link: "https://viprides.com.au/",
  stack: ["Gatsby.Js", "React.Js", "React Helmet"],
  design: ["Ant Design", "Icon creation", "Logo creation", "Web Design", "Animations"],
}
const vaire = {
  title: "Vaire.pl",
  description: "Brochure site for a pollution mask company",
  link: "https://vaire.pl/",
  stack: ["Gatsby.Js", "React.Js", "React Helmet"],
  design: ["Bootstrap", "Icon creation", "Web Design", "Animations"],
}
const dynamech = {
  title: "Dynamech Electro Power",
  description: "Corporate site for a solar power plant company",
  link: "https://dynamech.jaykch.com/",
  stack: ["React.Js", "Redux", "Webpack"],
  design: ["Bootstrap", "Icon creation", "Logo creation", "Web Design", "Animations"],
}
const dynopower = {
  title: "Dynopower",
  description: "Corporate site for a Transformer manufacturer",
  link: "https://dynopower.jaykch.com/",
  stack: ["React.Js", "Redux", "Webpack"],
  design: ["Ant Design", "Icon creation", "Logo creation", "Web Design", "Animations"],
}
const lavon = {
  title: "Lavon Global",
  description: "Portfolio site for a marketing agency (Under Construction)",
  link: "http://lavon.jaykch.com/",
  stack: ["Gatsby.Js", "React.Js", "React Helmet"],
  design: ["Bootstrap", "Icon creation", "Web Design", "Animations"],
}
const jaykch2017 = {
  title: "Old Jaykch Site",
  description: "My old personal site built on react + redux which has since been rebuilt on gatsby for better SEO",
  link: "http://old.jaykch.com/",
  stack: ["React.Js", "Redux", "Webpack"],
  design: ["Reactstrap", "Web Design", "Animations"],
}
const weatherfy = {
  title: "Weatherfy",
  description: "App that displays monthly weather forecast with over 100,000 locations (Data fetched from Open Weather API)",
  link: "http://weatherfy.jaykch.com/",
  git: "https://github.com/jaykch/weatherfy",
  stack: ["React.Js", "Redux", "Axios", "Webpack", "REST API"],
  design: ["Bootstrap", "Animations"],
}
const markdown = {
  title: "Markdown Previewer",
  description: "Handy app to preview markdown text in real-time",
  link: "https://jaykch.github.io/markdown-previewer/src/",
  git: "https://github.com/jaykch/markdown-previewer",
  stack: ["React.Js", "Webpack"],
}
const leaderBoard = {
  title: "Leader-board",
  description: "A leader-board template to display rankings (currently displaying free code camp ranking)",
  link: "http://leaderboard.jaykch.com/",
  git: "https://github.com/jaykch/camper-leaderboard",
  stack: ["React.Js", "Redux", "Axios", "Webpack", "REST API"],
  design: ["Bootstrap"],
}
const conferro = {
  title: "Conferro Heritae",
  description: "E-commerce for an art auction house. Includes automated online auction bidding",
  link: "https://www.conferroheritae.com",
  stack: ["Magento", "Javascript", "PHP", "MySQL"],
}
const ethereumFile = {
  title: "Ethereum File Share",
  description: "An ethereum + react.js app to share files through Ethereum blockchain",
  link: "https://www.youtube.com/watch?v=VOzPZUC81Fs&feature=youtu.be",
  git: "https://github.com/jaykch/ethereum-file-share",
  stack: ["React.Js", "Solidity", "Web3.Js", "Ethereum", "Metamask", "Mocha"],
  design: ["Bootstrap"],
}
const ethereumData = {
  title: "Ethereum Data Share",
  description: "An ethereum + react.js concept to store transcript data on Ethereum blockchain",
  link: "https://www.youtube.com/watch?v=jasEMlDE0Bs&feature=youtu.be",
  git: "https://github.com/jaykch/ethereum-data-share",
  stack: ["React.Js", "Solidity", "Web3.Js", "Ethereum", "Metamask", "Mocha"],
  design: ["Bootstrap"],
}
const decentralisedUniversity = {
  title: "Decentralised University",
  description: "An ethereum + react.js app to store and fetch transcripts on Ethereum blockchain",
  link: "https://www.youtube.com/watch?v=VOzPZUC81Fs&feature=youtu.be",
  git: "https://github.com/jaykch/decentralisedUniversity",
  stack: ["React.Js", "Solidity", "Web3.Js", "Ethereum", "Metamask", "Mocha"],
  design: ["Bootstrap"],
}
const yomb = {
  title: "The 16 year old maori boy",
  description: "Online academic test app for a maori school in New Zealand - I took over a previous developer and fixed their code, site still has a lot of issues.",
  link: "https://www.the16yearoldmaoriboy.co.nz/",
  stack: ["Laravel", "Javascript", "HTML", "CSS", "PHP"],
  design: ["Bootstrap", "Icon creation", "Web Design", "Animations", "Asset creation (Images)"],
}
const adhv = {
  title: "Australian Dental Health",
  description: "An online sign off form for a dental company which generates a PDF document that is compliant with the health standards in Australia and meets their legal obligation.",
  link: "https://jaykch.github.io/australian-dhv/",
  stack: ["Html", "CSS", "Javascript", "PHP", "MailChimp"],
  design: ["Bootstrap", "Web Design"],
}
const ticTacToe = {
  title: "Tic Tac Toe",
  description: "A multi-player tic tac toe game",
  link: "https://jaykch.github.io/tic-tac-toe/",
  git: "https://github.com/jaykch/tic-tac-toe",
  stack: ["Html", "CSS", "Javascript"],
  design: ["Web Design"],
}
const simon = {
  title: "Simon Game",
  description: "Classic simon game built on javascript",
  link: "https://jaykch.github.io/simon/",
  git: "https://github.com/jaykch/simon",
  stack: ["Html", "CSS", "Javascript"],
  design: ["Web Design"],
}
const multiForm = {
  title: "Multi Step Form",
  description: "A redesign of billysumo form",
  link: "https://jaykch.github.io/multi-step-form/",
  git: "https://github.com/jaykch/multi-step-form",
  stack: ["Html", "CSS", "Javascript", "PHP"],
  design: ["Bootstrap", "Web Design"],
}
const physicalPong = {
  title: "Physical Pong",
  description: "Physical pong game where you have to keep cycling to control the brightness of your side of the table and control your paddle with a bicycle handle. Additionally the game has craters and if the ball goes into a crater on your side your opponent gets a point. ",
  link: "https://www.youtube.com/watch?v=Drm_q5SCGFU",
  git: "https://github.com/jaykch/Physical-Pong",
  stack: ["Processing", "Arduino"],
}
const bugsAttack = {
  title: "Bugs Attack",
  description: "A game I built where you avoid bugs and try to get to the river and back. If you hit a bug you have to start from the beginning",
  link: "https://jaykch.github.io/udacity-frontend-nanodegree",
  git: "https://github.com/jaykch/udacity-frontend-nanodegree",
  stack: ["HTML", "CSS", "Javascript"],
  design: ["Asset creation (Icons and Elements)"],
}
const microbitMediaController = {
  title: "Micro:bit Media Controller",
  description: "A gesture control system to control a computers media key through gestures on a chip. (Please view link to see how it works)",
  link: "https://www.youtube.com/watch?v=CM8lSN1KUtg",
  git: "https://github.com/jaykch/microbit-media-controller",
  stack: ["Node.Js"],
}
const babylon = {
  title: "Babylon Day Party",
  description: "Promotional site for babylon day party",
  link: "https://jaykch.github.io/babylon",
  git: "https://github.com/jaykch/babylon",
  stack: ["Javascript", "HTML", "CSS"],
  design: ["Bootstrap", "Icon creation", "Web Design"],
}
const mukiSports = {
  title: "Muki Sports",
  description: "Informational site for muki sports",
  link: "https://jaykch.github.io/mukisports",
  git: "https://github.com/jaykch/mukisports",
  stack: ["Javascript", "HTML", "CSS"],
  design: ["Bootstrap", "Icon creation", "Web Design"],
}
const jovian = {
  title: "Jovian Technologies",
  description: "Company site for Jovian technologies",
  link: "https://joviantech.github.io",
  stack: ["Javascript", "HTML", "CSS"],
  design: ["Bootstrap", "Icon creation", "Web Design", "Asset creation (Videos and Images)"],
}
const pomodoroClock = {
  title: "Pomodoro Clock",
  description: "A javascript pomodoro clock",
  link: "https://jaykch.github.io/pomodoro-clock",
  git: "https://github.com/jaykch/pomodoro-clock",
  stack: ["Html", "CSS", "Javascript"],
  design: ["Web Design"],
}
const wikiViewer = {
  title: "WikiViewer",
  description: "A wikipedia search engine",
  link: "https://jaykch.github.io/wiki-viewer",
  git: "https://github.com/jaykch/wiki-viewer",
  stack: ["Html", "CSS", "Javascript"],
  design: ["Web Design"],
}
const randomQuoteGenerator = {
  title: "Random Quote Generator",
  description: "A random quote generator for motivation",
  link: "http://quotes.jaykch.com",
  git: "https://github.com/jaykch/random-quote-generator",
  stack: ["Html", "CSS", "Javascript"],
  design: ["Web Design"],
}
const calculator = {
  title: "Calculator",
  description: "A javascript calculator",
  link: "https://jaykch.github.io/calculator",
  git: "https://github.com/jaykch/calculator",
  stack: ["Html", "CSS", "Javascript"],
  design: ["Web Design"],
}
const teslaTribute = {
  title: "Tesla Tribute",
  description: "A tribute to my idol Nikola Tesla",
  link: "https://jaykch.github.io/tesla-tribute",
  git: "https://github.com/jaykch/tesla-tribute",
  stack: ["Html"],
  design: ["Bootstrap"],
}
const twitch = {
  title: "Twitch Status",
  description: "A twitch api based app to check if given users exist and if they do what is their online status",
  link: "https://jaykch.github.io/twitch-fcc",
  git: "https://github.com/jaykch/twitch-fcc",
  stack: ["Html", "Javascript"],
}
const coinGame = {
  title: "Australian 2UP game",
  description: "Java iteration of Australian 2UP game that is commonly played on Anzac Day",
  link: "https://www.youtube.com/watch?v=Vu6wr8zWLak",
  git: "https://github.com/jaykch/CoinGameGUI",
  stack: ["Java"],
}
const miRide = {
  title: "MiRide Booking",
  description: "A Java booking app to make car bookings for a taxi company (Concept)",
  link: "https://github.com/jaykch/MiRide-S3770282",
  git: "https://github.com/jaykch/MiRide-S3770282",
  stack: ["Java"],
}

const cerberus = {
  title: "Cerberus",
  description: "A cryptocurrency tracker that shows analytics about cryptocurrencies like price feeds, tweets, github links, community sentiment etc.",
  link: "https://cerberus.saren.io/",
  stack: ["Node.Js", "React.Js", "MongoDB", "Express.js", "Web3.js", "Solidity"],
  design: ["Ante Design", "Icon creation", "Web Design", "Animations"],
}

const tintWarehouse = {
  title: "Shopify Warehouse",
  description: "A desktop app for warehouse automation from shopify orders. You can manage packing, shipping and dispatch all from one place.",
  link: "https://github.com/jaykch/shopify-electron-client",
  git: "https://github.com/jaykch/shopify-electron-client",
  stack: ["React.Js", "Electron.js", "TypeScript"],
  design: ["Ante Design", "Icon creation", "Graphic Design", "Animations"],
}

// --------------------Sorted Arrays------------------------

export const REACT_APPS = [
  tintWarehouse,
  excyted,
  cerberus,
  enenza,
  jayDocs,
  vipRides,
  vaire,
  dynamech,
  dynopower,
  lavon,
  jaykch2017,
  weatherfy,
  markdown,
  leaderBoard,
  ethereumFile,
  ethereumData,
  decentralisedUniversity,
]

export const FULLSTACK_APPS = [
  tintWarehouse,
  cerberus,
  enenza,
  jayDocs,
  conferro,
  adhv,
  yomb,
  coinGame,
  miRide,
  ethereumFile,
  ethereumData,
  decentralisedUniversity,
]

export const BLOCKCHAIN_APPS = [
  cerberus,
  ethereumFile,
  ethereumData,
  decentralisedUniversity,
]

export const GAMES = [
  physicalPong,
  bugsAttack,
  ticTacToe,
  simon,
  coinGame,
]

export const NODE_APPS = [
  cerberus,
  enenza,
  microbitMediaController,
]

export const MISC_APPS = [
  babylon,
  mukiSports,
  teslaTribute,
  jovian
]

export const WIDGETS = [
  weatherfy,
  pomodoroClock,
  randomQuoteGenerator,
  markdown,
  wikiViewer,
  calculator,
  leaderBoard,
  twitch,
  multiForm,
  jayDocs,
]