import React from "react"
import "./portfolio.scss"
import { Col, Container, Row } from "react-bootstrap"
import { motion } from "framer-motion"
import PortfolioItem from "./portfolioItem"
import { GiConsoleController, GiStack } from "react-icons/gi"
import { FaReact, FaNode, FaEthereum } from "react-icons/fa"
import { MdWidgets, MdShoppingCart } from "react-icons/md"
import { AiFillCodeSandboxCircle } from "react-icons/ai"
import { REACT_APPS, FULLSTACK_APPS, BLOCKCHAIN_APPS, GAMES, NODE_APPS, WIDGETS, MISC_APPS } from "./data"

const Work = () => {
  return (
    <motion.section id="portfolio" initial={{ scale: 0.5 }} animate={{ scale: 0.96 }}>
      <Container fluid>
        <Row>
          <Col md={12} className="text-container">
            <h1>Portfolio</h1>
          </Col>
          <PortfolioItem id="react" title="react/gatsby applications" icon={<FaReact/>} data={REACT_APPS}/>
          <PortfolioItem id="fullstack" title="full stack applications" icon={<GiStack/>} data={FULLSTACK_APPS}/>
          <PortfolioItem id="node" title="node.js applications" icon={<FaNode/>} data={NODE_APPS}/>
          <PortfolioItem id="blockchain" title="blockchain applications" icon={<FaEthereum/>} data={BLOCKCHAIN_APPS}/>
          <PortfolioItem id="games" title="games" icon={<GiConsoleController/>} data={GAMES}/>
          <PortfolioItem id="other" title="miscellaneous projects" icon={<AiFillCodeSandboxCircle/>}
                         data={MISC_APPS}/>
          <PortfolioItem id="widgets" title="widgets" icon={<MdWidgets/>} data={WIDGETS}/>
          <PortfolioItem id="shop" title="the shop" icon={<MdShoppingCart/>} to="http://shop.jaykch.com/" data={[]}/>
        </Row>
      </Container>
    </motion.section>
  )
}

export default Work
