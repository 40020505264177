import React from "react"
import { Modal } from "react-bootstrap"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import PortfolioShowcase from "./portfolioShowcase"
import { MdClose } from "react-icons/md"

const PortfolioItem = (props) => {
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <Col xs={6} md={3} id={props.id} className="portfolio-item">
      <OverlayTrigger
        key={props.id}
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${props.title}`}>
            Check Out <strong>{props.title}</strong> I have built.
          </Tooltip>
        }>
        {!props.to ? <div onClick={handleShow}>
            <motion.div className="work-thumbnail" whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.7 }}>{props.icon} </motion.div>
          </div> :
          <motion.a href={props.to} target="_blank" rel="noopener noreferrer" className="work-thumbnail"
                    whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.7 }}>{props.icon}</motion.a>}

      </OverlayTrigger>

      <Modal show={show} onHide={handleClose} centered id="portfolio-modal">
        <Modal.Body>
          <MdClose onClick={handleClose} className="close-button"/>
          <Container>
            <Row>
              <h2 style={{ textTransform: "capitalize" }}>{props.title}</h2>
            </Row>
          </Container>
          <PortfolioShowcase data={props.data}/>
        </Modal.Body>
      </Modal>
    </Col>
  )
}

PortfolioItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
}

export default PortfolioItem
