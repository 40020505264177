import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Work from "../components/work"

const Portfolio = (props) => (
  <Layout>
    <SEO title="Portfolio" description="A list of projects I have worked on over the years"/>
    <Work/>
  </Layout>
)

export default Portfolio